import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { get } from '@docavenue/core';
import { useGet } from '@maiia/model/generated/model/api-patient/hooks/patUsersMe';

import { omit } from 'lodash';
import { authActions } from '@/src/actions';

const CurrentUserSynchroniser = () => {
  const { data: modelUser, isSuccess } = useGet();
  const reduxUser = useSelector((state: any) =>
    get(state, 'authentication.item'),
  );
  const dispatch = useDispatch();

  // Temporary workaround: legacy features still depends on redux, new code relies on model generator. Both caches are independant, and this can causes issues (like with WithAuth).
  // The following useEffect makes sure redux data is updated when /patUsersMe data are invalidated.
  useEffect(() => {
    if (!isSuccess) return;

    // Redux stores the auth JWT token in authentication.item as the 'token' and 'refresh' props.
    // In some cases, the /pat/users/me endpoint returns a token props, which is not related to auth and can overwrite the JWT.
    const userWithoutToken = omit(modelUser, ['token', 'refresh']);

    dispatch(
      authActions.setItem({
        ...reduxUser,
        ...userWithoutToken,
      }),
    );
    // reduxUser is omitted as it should not trigger the effect. Its value will still be fresh in the dispatch.
  }, [modelUser, isSuccess]);

  return null;
};

export default CurrentUserSynchroniser;
