/* eslint-disable import/prefer-default-export */
import he from 'he';
import buildArticleUrl from '@/components/utils/buildArticleUrl';

const DYNAMIC_FOOTER_ARTICLES_LIMIT = 10;

type DynamicFooterLink = {
  id: number;
  title: string;
  url: string;
  className?: string;
};

const formatForDynamicFooterList = (
  article: ChildArticle,
  className?: string,
): DynamicFooterLink => ({
  id: article.id,
  title: he.decode(article.title.rendered),
  url: buildArticleUrl(article.link),
  className,
});

export const dynamicFooterArticlesList = (
  indexArticle: ArticleDTO,
  limited: boolean = false,
): DynamicFooterLink[] => {
  if (!indexArticle) return [];

  const parentArticles = indexArticle.childArticles;
  let finalArticlesList: ChildArticle[] = [];

  if (limited) {
    // In case the limit is < the number of parent articles
    if (DYNAMIC_FOOTER_ARTICLES_LIMIT < parentArticles.length) {
      finalArticlesList = parentArticles
        .slice(0, DYNAMIC_FOOTER_ARTICLES_LIMIT)
        .map(article => ({ ...article, childArticles: [] }));
    } else {
      // In case the limit is >= the number of parent articles
      const childrenLengths = parentArticles.map(
        article => article.childArticles.length,
      );
      const childrenTotal = childrenLengths.reduce((total, cur) => {
        return total + cur;
      }, 0);

      const childrenToShow = childrenLengths?.map(() => 0);

      // Check if the limit > the sum of all child articles
      let articlesLeft = Math.min(
        DYNAMIC_FOOTER_ARTICLES_LIMIT - childrenLengths.length,
        childrenTotal,
      );

      while (articlesLeft > 0) {
        // eslint-disable-next-line no-loop-func
        childrenToShow.forEach((childrenCount: number, idx: number) => {
          if (articlesLeft > 0 && childrenLengths[idx] > childrenCount) {
            childrenToShow[idx]++;
            articlesLeft--;
          }
        });
      }

      finalArticlesList = parentArticles.map((article, idx) => ({
        ...article,
        childArticles: article.childArticles.slice(0, childrenToShow[idx]),
      }));
    }
  } else {
    finalArticlesList = parentArticles;
  }

  return finalArticlesList.reduce((list: DynamicFooterLink[], article) => {
    return [
      ...list,
      formatForDynamicFooterList(article),
      ...article.childArticles.map(childArticle =>
        formatForDynamicFooterList(childArticle, 'with-disc'),
      ),
    ];
  }, []);
};
