import { useRef, useEffect, useState } from 'react';
import { useCurrentUser } from '@docavenue/components';
import { useStore } from 'react-redux';
import parser from 'ua-parser-js';

import { addReducers, removeReducers } from '../reducers';
import useBranding from './branding/useBranding';

type ApplicationType =
  | 'WEB_PUBLIC'
  | 'WEB_TLC_RELAY'
  | 'WEB_PUBLIC_MOBILE'
  | 'WEB_PUBLIC_TABLET'
  | 'WEB_PUBLIC_DESKTOP';

export { default as useUserLocation } from './useUserLocation';

export {
  useMediaQueryBreakPoint,
  IS_XS,
  IS_SM,
  IS_MD,
  IS_LG,
  IS_PHONE_OR_TABLET,
} from './useMediaQueryBreakPoint';

export { default as useAsyncError } from './useAsyncError';
export { default as useFirstAvailability } from './useFirstAvailability';
export { default as useIsMounted } from './useIsMounted';
export { default as useCentersTree } from './useCentersTree';

export const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const useApplicationType = (): ApplicationType => {
  const { data: branding } = useBranding();
  if (typeof window === 'undefined') {
    // eslint-disable-next-line no-console
    // console.trace('useApplicationType only apply for client side');
    return 'WEB_PUBLIC';
  }

  const isPharmacy = Boolean(branding?.id);
  if (isPharmacy) return 'WEB_TLC_RELAY';

  const { device } = parser(navigator.userAgent);
  const deviceType = device?.type?.toLowerCase();

  if (deviceType === 'mobile') return 'WEB_PUBLIC_MOBILE';
  if (deviceType === 'tablet') return 'WEB_PUBLIC_TABLET';
  return 'WEB_PUBLIC_DESKTOP';
};

export const useSaga = saga => {
  const store = useStore();
  useEffect(() => {
    const task = (store as any)?.addSaga(saga);
    return () => task.cancel();
  }, []);
};

export const useReducers = reducers => {
  useEffect(() => {
    addReducers(reducers);
    return () => removeReducers(reducers);
  }, []);
};

export const useIsTheOnlyPatient = () => {
  const defaultUser = useCurrentUser();

  // 25/02/2025 : areybeaud
  // Here we return something other than true, only if the patient
  // information is available
  // It might not be available while loading, and previously we returned false
  // which led to a NullReferenceException in <PatientNameTag>
  if (!defaultUser?.userPatientInformation?.userPatientProfiles) {
    return true;
  }

  return defaultUser.userPatientInformation.userPatientProfiles.length === 1;
};

// https://usehooks.com/useOnScreen/
export const useOnScreen = <T extends Element>(
  ref: React.RefObject<T>,
  rootMargin: string = '0px',
  cb?: (arg0: boolean) => void,
) => {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);
  useEffect(() => {
    if (ref === null) return;
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        const { isIntersecting: newValue } = entry;
        setIntersecting(newValue);
        if (typeof cb === 'function') cb(newValue);
      },
      {
        rootMargin,
      },
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) observer.unobserve(ref.current);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount
  return isIntersecting;
};
